import './App.css';
import { Button, IconSettings, Alert, AlertContainer, Icon, Card, CardEmpty, DataTableCell, DataTableInteractiveLink, Spinner } from '@salesforce/design-system-react';
import React from 'react';

function refreshActionbleCases(appComponent, targetComponent)
{
  appComponent.setState({timerState: 'stop'});
  setTimeout(() => appComponent.setState({timerState: 'run'}), 10);
  refreshData(appComponent, targetComponent, 'actionableCases', c => {
      c.id = c.Id;
      c.Due_Date_With_Flag__c = c.Due_Date_With_Flag__c ? c.Due_Date_With_Flag__c.substring(2) : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Account_Shortcode = c.Account ? c.Account.Shortcode__c : '';
      return c;
    });
}

function refreshNonActionbleCases(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'nonActionableCases', c => {
      c.id = c.Id;
      c.Due_Date_With_Flag__c = c.Due_Date_With_Flag__c ? c.Due_Date_With_Flag__c.substring(2) : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Account_Shortcode = c.Account ? c.Account.Shortcode__c : '';
      return c;
    });
}

function refreshNonActionbleCasesOlder(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'nonActionableCasesOlder', c => {
      c.id = c.Id;
      c.Due_Date_With_Flag__c = c.Due_Date_With_Flag__c ? c.Due_Date_With_Flag__c.substring(2) : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Account_Shortcode = c.Account ? c.Account.Shortcode__c : '';
      return c;
    });
}

function refreshPeerReviewCases(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'peerReviewCases', c => {
      c.id = c.Id;
      c.Due_Date_With_Flag__c = c.Due_Date_With_Flag__c ? c.Due_Date_With_Flag__c.substring(2) : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Account_Shortcode = c.Account ? c.Account.Shortcode__c : '';
      return c;
    });
}

function refreshClosedCasesRecent(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'closedCasesRecent', c => {
      c.id = c.Id;
      c.ClosedDate_String = c.ClosedDate ? c.ClosedDate.substring(0, 10) : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Account_Shortcode = c.Account ? c.Account.Shortcode__c : '';
      return c;
    });
}

function refreshUnassignedCasesRecent(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'openCasesUnassignedRecent', c => {
      c.id = c.Id;
      c.Contact_Name = c.Contact && c.Contact.Name ? c.Contact.Name : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Contact_Url = c.Contact && c.Contact.Id ? 'https://trausteknik.lightning.force.com/' + c.Contact.Id : '';
      return c;
    });
}

function refreshUnassignedCases(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'openCasesUnassigned', c => {
      c.id = c.Id;
      c.Contact_Name = c.Contact && c.Contact.Name ? c.Contact.Name : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Contact_Url = c.Contact && c.Contact.Id ? 'https://trausteknik.lightning.force.com/' + c.Contact.Id : '';
      return c;
    });
}

function refreshOnHoldCases(appComponent, targetComponent)
{
  refreshData(appComponent, targetComponent, 'onHoldCases', c => {
      c.id = c.Id;
      c.ClosedDate_String = c.ClosedDate ? c.ClosedDate.substring(0, 10) : '';
      c.Contact_Name = c.Contact && c.Contact.Name ? c.Contact.Name : '';
      c.CaseUrl = 'https://trausteknik.lightning.force.com/' + c.Id;
      c.AccountUrl = 'https://trausteknik.lightning.force.com/' + c.AccountId;
      c.Contact_Url = c.Contact && c.Contact.Id ? 'https://trausteknik.lightning.force.com/' + c.Contact.Id : '';
      return c;
    });
}

async function refreshData(appComponent, targetComponent, filterName, mappingFn) {
  targetComponent.setState({isLoading: true});
  let fetchResultFetch = await fetch('https://api.tttest.pjim.me/cases.php?query=' + filterName, {credentials: "include"});
  let fetchResult = await fetchResultFetch.json();
  if(fetchResult.status === 'ok')
  {
    let newCasesList = fetchResult.data.records.map(mappingFn);
    targetComponent.setState({items: newCasesList});
    appComponent.setState({needsLogin: false});
  }
  else
  {
    targetComponent.setState({items: []});
    if(fetchResult.status === 'login_required')
    {
      appComponent.setState({needsLogin: true});
    }
    else
    {
      appComponent.setState({needsLogin: false});
    }
  }
  targetComponent.setState({isLoading: false});
}

class LinkableTableCell extends React.Component {
  getLinkUrl()
  {
    return this.props.item[this.props.url];
  }
  render() {
    return (
  <DataTableCell {...this.props} >
    <DataTableInteractiveLink
			onClick={(event) => {
				event.preventDefault();
			}}
		>
      {this.props.children}
    </DataTableInteractiveLink>
  </DataTableCell>
    );
  }
}
LinkableTableCell.displayName = DataTableCell.displayName;

const CustomDataTableCell = ({ children, ...props }) => (
	<DataTableCell {...props}>
		<DataTableInteractiveLink
			onClick={(event) => {
				event.preventDefault();
			}}
		>
			{children}
		</DataTableInteractiveLink>
	</DataTableCell>
);
CustomDataTableCell.displayName = DataTableCell.displayName;

class BasicDataTable extends React.Component
{
  getColumnHeadingObjs(parentKey)
  {
    return this.props.children.map(c => {
      let fieldLabel = c.props.label;
      let key = this.props.id + '-' + fieldLabel;
      let styleText = {};
      if(c.props.width)
      {
        styleText['width'] = c.props.width;
      }
      return (
        <th key={key} aria-label={fieldLabel} aria-sort="none" className="slds-is-resizable slds-is-sortable slds-cell_action-mode" style={styleText} scope="col">
          <button className="slds-th__action slds-text-link_reset slds-button" tabIndex="0">
            <span className="slds-assistive-text">Sort by: </span>
            <div className="slds-grid slds-grid_vertical-align-center slds-has-flexi-truncate">
              <span className="slds-truncate" title={fieldLabel}>{fieldLabel}</span>
              <span className="slds-icon_container slds-icon-utility-arrowdown">
                <svg className="slds-icon slds-icon-text-default slds-is-sortable__icon " aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#arrowdown"></use>
                </svg>
              </span>
            </div>
          </button>
          <div className="slds-resizable">
            <input type="range" aria-label="Name column width" className="slds-resizable__input slds-assistive-text" id="cell-resize-handle-540" max="1000" min="20" tabIndex="0" />
            <span className="slds-resizable__handle">
              <span className="slds-resizable__divider"></span>
            </span>
          </div>
        </th>
      );
    })
  }

  getCellObjs(rowData, parentKey)
  {
    return this.props.children.map((colData, i) => {
      let key = parentKey + '-' + colData.props.property;
      let styleText = {};
      if(colData.props.width)
      {
        styleText['width'] = colData.props.width;
      }
      if(colData.props.url)
      {
          return (
            <td key={key} className="slds-cell_action-mode" style={styleText}>
              <div className="slds-truncate" title={rowData[colData.props.property]}>
                <a href={rowData[colData.props.url]} tabIndex="0">{rowData[colData.props.property]}</a>
              </div>
            </td>
          );
      }
      else
      {
        if(colData.props.truncate)
        {
          return (
            <td key={key} className="slds-cell_action-mode" role="gridcell" style={styleText}>
              <div className="slds-truncate" title={rowData[colData.props.property]}>{rowData[colData.props.property]}</div>
            </td>
          );
        }
        else 
        {
          return (
            <td key={key} className="slds-cell_action-mode" role="gridcell" style={styleText}>
              <div title={rowData[colData.props.property]}>{rowData[colData.props.property]}</div>
            </td>
          );
        }
      }
    });
  }

  getRowObjs()
  {
    if(this.props.isLoading)
    {
      return;
    }
    else
    {
      return this.props.items.map(r => {
        let key = this.props.id + '-' + r.Id;
        return (
          <tr key={key} aria-selected="false" className="slds-hint-parent">
            {this.getCellObjs(r, key)}
          </tr>
        );
      })
    }
  }

  getLoadingObj()
  {
    if(this.props.isLoading)
    {
      return (
        <div style={{ position: 'relative', height: '5rem' }}>
				<Spinner
					size="small"
					variant="base"
					assistiveText={{ label: 'Main Frame Loading...' }}
				/>
			</div>
      );
    }
    else
    {
      return;
    }
  }

  render() {
    return (
      <div>
      <table className="slds-table slds-table_bordered slds-table_fixed-layout slds-table_resizable-cols" role="grid" aria-label="Example advanced table of Opportunities in actionable mode with resized column">
        <thead>
          <tr className="slds-line-height_reset">
            {this.getColumnHeadingObjs()}
          </tr>
          </thead>
          <tbody>
            {this.getRowObjs()}
          </tbody>
      </table>
      {this.getLoadingObj()}
      </div>
    );

  }
}

class BasicDataTableColumn extends React.Component {

}

let openCasesColumnsBasic = [
  <BasicDataTableColumn
                key="CaseNumber"
								label="Case #"
								property="CaseNumber"
                url="CaseUrl"
                width="5.2rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Account_Name__c"
								label="Account"
								property="Account_Shortcode"
                url="AccountUrl"
                width="4.00rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Subject"
								label="Subject"
								property="Subject"
                width="60%"
                truncate
							/>,
  <BasicDataTableColumn
                key="Status"
								label="Status"
								property="Status_Label"
                width="5.85rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Priority"
								label="Priority"
								property="Priority_Label"
                width="4rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Due_Date_With_Flag__c"
								label="Due Date"
								property="Due_Date_With_Flag__c"
                width="6.25rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Next_Action__c"
								label="Next Action"
								property="Next_Action__c"
                width="20%"
                truncate
							/>,

];

let closedCasesColumnsBasic = [
  <BasicDataTableColumn
                key="CaseNumber"
								label="Case #"
								property="CaseNumber"
                url="CaseUrl"
                width="5.2rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Account_Name__c"
								label="Account"
								property="Account_Name__c"
                url="AccountUrl"
                width="5.2rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Subject"
								label="Subject"
								property="Subject"
                truncate
							/>,
  <BasicDataTableColumn
                key="Status"
								label="Status"
								property="Status_Label"
                width="4rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="ClosedDate"
								label="Closed Date"
								property="ClosedDate_String"
                width="5.85rem"
                truncate
							/>,

];

let peerReviewCasesColumnsBasic = [
  <BasicDataTableColumn
                key="CaseNumber"
								label="Case #"
								property="CaseNumber"
                url="CaseUrl"
                width="5.2rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Account_Name__c"
								label="Account"
								property="Account_Name__c"
                url="AccountUrl"
                truncate
							/>,
  <BasicDataTableColumn
                key="Subject"
								label="Subject"
								property="Subject"
                truncate
							/>,
  <BasicDataTableColumn
                key="Owner"
								label="Owner"
								property="Case_Owner_FriendlyName__c"
                width="5rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Due_Date_With_Flag__c"
								label="Due Date"
								property="Due_Date_With_Flag__c"
                width="5.85rem"
                truncate
							/>,

];

let unassignedCasesColumnsBasic = [
  <BasicDataTableColumn
                key="CaseNumber"
								label="Case #"
								property="CaseNumber"
                url="CaseUrl"
                width="5.2rem"
                truncate
							/>,
  <BasicDataTableColumn
                key="Account_Name__c"
								label="Account"
								property="Account_Name__c"
                url="AccountUrl"
                truncate
							/>,
  <BasicDataTableColumn
                key="Subject"
								label="Subject"
								property="Subject"
                truncate
							/>,
  <BasicDataTableColumn
                key="ContactName"
								label="Contact"
								property="Contact_Name"
								url="Contact_Url"
                truncate
							/>,

];

class CardedList extends React.Component {
	static displayName = 'CardExample';

  constructor(args)
  {
    super(args);
    this.state = {
      items: [],
      isLoading: true,
      isFiltering: false,
    };

    if(this.props.refreshTime)
    {
      setInterval(() => {
        // Only refresh if page is hidden.
        if(!document.hidden)
        {
        }
        this.props.refreshAction(this);
      }, 1000 * 60 * this.props.refreshTime);
    }
  }

  componentDidMount() {
    this.props.refreshAction(this);
  }

  handleRefreshButton() {
    this.props.refreshAction(this);
  }

	render() {
		const isEmpty = this.state.items.length === 0;

    let key = this.props.id + '-header';
		return (
			<IconSettings iconPath="/assets/icons">
				<div className="slds-grid slds-grid_vertical hideOverflow cardOuter">
					<Card
						id={key}
						headerActions={
							(
								<Button
									label="Refresh"
									onClick={() => this.handleRefreshButton()}
								/>
							)
						}
						heading={this.props.title}
						icon={<Icon category="standard" name="document" size="small" />}
						empty={
							!this.state.isLoading && isEmpty ? (
								<CardEmpty heading="No Items">
								</CardEmpty>
							) : null
						}
					>
						<BasicDataTable
              items={this.state.items}
              id="BasicDataTable-1"
              fixedLayout
              keyboardNavigation
              resizable
              resizableOptions={{
                onResize: (columnsResized) => {
                  console.log(JSON.stringify(columnsResized));
                },
              }}
              isLoading={this.state.isLoading}
              >
              {this.props.columns}
						</BasicDataTable>
					</Card>
				</div>
			</IconSettings>
		);
	}
}

class App extends React.Component {
  constructor(args)
  {
    super(args);
   
    this.state = {needsLogin: false, timerState: 'run'};

    let appComponent = this;
    this.refreshActionbleCases = (targetComponent) => refreshActionbleCases(appComponent, targetComponent);
    this.refreshNonActionbleCases = (targetComponent) => refreshNonActionbleCases(appComponent, targetComponent);
    this.refreshClosedCasesRecent = (targetComponent) => refreshClosedCasesRecent(appComponent, targetComponent);
    this.refreshNonActionbleCasesOlder = (targetComponent) => refreshNonActionbleCasesOlder(appComponent, targetComponent);
    this.refreshPeerReviewCases = (targetComponent) => refreshPeerReviewCases(appComponent, targetComponent);
    this.refreshUnassignedCasesRecent = (targetComponent) => refreshUnassignedCasesRecent(appComponent, targetComponent);
    this.refreshUnassignedCases = (targetComponent) => refreshUnassignedCases(appComponent, targetComponent);
    this.refreshOnHoldCases = (targetComponent) => refreshOnHoldCases(appComponent, targetComponent);
  }

  loginBar()
  {
    if(this.state.needsLogin)
    {
      return (
        <div className="alertBar">
          <IconSettings iconPath="/assets/icons">
            <AlertContainer>
              <Alert
                icon={<Icon category="utility" name="user" />}
                labels={{
                  heading: <span>Login needed to work. <a href="https://trausteknik.my.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9n_HvETGhr3Br3dtk1y99EVQrRZ6DTQLyLYA9_RTg0N4hHoJDyVb_FcPuPgkqMhQA7WHckU2nm6pyfuc7&redirect_uri=https://api.tttest.pjim.me/callback.php">Log In</a></span>,
                }}
                onClickHeadingLink={() => console.log('onClickHeadingLink')}
              />
            </AlertContainer>
          </IconSettings>
        </div>
      );
    }
    else return <p></p>;
  }

  timerClasses()
  {
    if(this.state.timerState === 'run')
    {
      return 'timerBody runTimer'
    }
    else if(this.state.timerState === 'stop')
    {
      return 'timerBody'
    }
    else if(this.state.timerState === 'reset')
    {
      return 'timerBody runTimer'
    }
    else return '';
  }

  render() {
  return (
    <div className="App">
      {this.loginBar()}
      <div className="timerContainer">
        <div className={this.timerClasses()}></div>
      </div>
    <div className="mainPanes">
      <div className="slds-grid slds-gutters">
        <div className="slds-col slds-size_1-of-2">
              <CardedList title="My cases that need attention" columns={openCasesColumnsBasic} refreshAction={this.refreshActionbleCases} refreshTime="5"></CardedList>
              <CardedList title="My cases to follow up" columns={openCasesColumnsBasic} refreshAction={this.refreshNonActionbleCases} refreshTime="5"></CardedList>
              <CardedList title="My recently closed cases" columns={closedCasesColumnsBasic} refreshAction={this.refreshClosedCasesRecent}></CardedList>
        </div>
        <div className="slds-col slds-size_1-of-2">
            <CardedList title="My older cases to follow up" columns={openCasesColumnsBasic} refreshAction={this.refreshNonActionbleCasesOlder}></CardedList>
            <CardedList title="Peer review cases" columns={peerReviewCasesColumnsBasic} refreshAction={this.refreshPeerReviewCases} refreshTime="5"></CardedList>
            <CardedList title="Recent cases Unassigned" columns={unassignedCasesColumnsBasic} refreshAction={this.refreshUnassignedCasesRecent} refreshTime="5"></CardedList>
            <CardedList title="Unassigned Cases" columns={unassignedCasesColumnsBasic} refreshAction={this.refreshUnassignedCases} refreshTime="5"></CardedList>
            <CardedList title="My On-Hold Cases" columns={closedCasesColumnsBasic} refreshAction={this.refreshOnHoldCases} refreshTime="5"></CardedList>
        </div>
      </div>
    </div>
    </div>
  );
  }
}

export default App;
